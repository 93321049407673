import LocalizedStrings from 'react-localization';
import {getProfessioniUtente, getProvince, getSpecializzazioniUtente, getTitoliUtente} from "../utility/UserValues";

export let strings = new LocalizedStrings({
  it: {
    general:{
      attenzione:"Attenzione",
      ok:"OK",
      operazioneOk:"Operazione eseguita con successo",
      input_not_valid:"Inserire un valore valido",
      loading:"Caricamento dati in corso",
      error_retry:"Si è verificato un errore. Riprovare",
      no_data_table:"Nessun dato disponibile",
      select_entity:"Seleziona una voce dal menù",
      add_new_answer:"Aggiungi altra risposta",
      add_new_homeData:"Aggiungi contenuto",
      caricamento_in_corso:"Caricamento in corso",
      modifica:"Modifica",
      conferma:"Conferma",
      annulla:"Annulla",
    },
    colors:{
      info:"success",
    },
    platformName : "ANMCO Events",

    errors:{
      general_error:"Si e' verificato un errore. Riprovare."
    },

    users:{
      name:"Utente",
      confirm_delete:"Sicuro di voler cancellare l'utente selezionato?",
      new_user:"Nuovo utente",
      edit_user:"Modifica utente",
      list_label:"Lista Membri",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta del nuovo utente.",
        edit:"Si è verificato un errore durante la modifica dell'utente.",
        loading:"Si è verificato un errore durante il caricamento degli utenti.",
      }
    },
    userModel: {
      /*username: {
        name:"Username",
        required:true,
        disabledInEditMode:true,
        type:"text",
      },*/
      titolo: {
        name:"Titolo",
        required:false,
        type:"select",
        options:getTitoliUtente()
      },
      cognome: {
        name:"Cognome",
        required:true,
        type:"text"
      },
      nome: {
        name:"Nome",
        required:true,
        type:"text"
      },
      dataDiNascita: {
        name:"Data di nascita",
        required:false,
        type:"date"
      },
      provinciaDiNascita: {
        name:"Provincia di nascita",
        required:false,
        type:"select",
        options:getProvince()
      },
      cittaDiResidenza: {
        name:"Città di residenza",
        required:false,
        type:"text"
      },
      codiceFiscale: {
        name:"Codice Fiscale",
        required:false,
        type:"text"
      },
      telefono: {
        name:"Telefono",
        required:false,
        type:"number"
      },

      professione: {
        name:"Professione",
        required:false,
        type:"select",
        isCustomSelect:true,
        options:getProfessioniUtente()
      },

      specializzazione: {
        name:"Specializzazione",
        required:false,
        type:"select",
        isCustomSelect:true,
        options:getSpecializzazioniUtente()
      },
      numeroIscrizioneOrdine: {
        name:"Iscrizione ordine",
        required:false,
        type:"text"
      },
      provinciaIscrizioneOrdine: {
        name:"Prov. iscrizione ordine",
        required:false,
        type:"select",
        options:getProvince()
      },
      email: {
        name:"Email",
        required:true,
        type:"email"
      },
      password: {
        name:"Password",
        required:true,
        requiredInEditMode:false,
        type:"password"
      },

      richiestaAggiornamentoPassword: {
        name:"Richiesta aggiornamento password",
        required:true,
        requiredInEditMode:false,
        type:"boolean"
      },


    },


    userCongress:{
      name:"Utente in Congresso",
      name_remote: "Utente Remoto in Congresso",
      new:"Nuovo utente del congresso",
      new_remote:"Nuovo utente remoto del congresso",
      edit:"Modifica utente",
      list_label:"Lista utenti del congresso",
      export_stats:"Esporta Statistiche",
      confirm_delete:"Sicuro di voler cancellare l'associazione tra l'utente ed il beacon?",
        riassegnaBeacon:"Riassegna Beacon",
      errors:{
        add:"Si è verificato un errore durante la creazione dell'associazione tra utene e beacon.",
        edit:"Si è verificato un errore durante la modifica dell'associazione tra utene e beacon..",
        loading:"Si è verificato un errore durante il caricamento degli utenti del congresso.",
        loading_data:"Si è verificato un errore durante il caricamento dei dati. Riprovare",
        no_user_available:"Non ci sono utenti liberi disponibili",
        no_beacon_available:"Non ci sono beacon liberi disponibili",
        reassign_beacon:"Si è verificato un errore durante la riassegnazione del beacon",
      }
    },
    userCongressModel: {
      users: {
        name: "Utente",
        type: "select",
        disabledInEditMode: true,
        alreadySelectedInEditMode: true,
        alreadySelectedInCreateMode: false,
        options:[
        ]
      },
      beacons: {
        name: "Beacon",
        type: "select",
        alreadySelectedInEditMode: false,
        alreadySelectedInCreateMode: false,
        disabledInEditMode: false,
        options:[
        ]
      },

    },

    remoteUserCongressModel: {
      users: {
        name: "Utente",
        type: "select",
        disabledInEditMode: true,
        alreadySelectedInEditMode: true,
        alreadySelectedInCreateMode: false,
        options:[
        ]
      }
    },

    faculty:{
      name:"Faculty",
    },

    congress:{
      name:"Congresso",
      confirm_delete:"Sicuro di voler cancellare il congresso selezionato?",
      new_congress:"Nuovo congresso",
      edit_congress:"Modifica congresso",
      list_label:"Lista Congressi",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta del nuovo congresso.",
        edit:"Si è verificato un errore durante la modifica del congresso.",
        loading:"Si è verificato un errore durante il caricamento dei congressi.",
        loadingUser:"Si è verificato un errore durante il caricamento degli utenti.",
        generazioneAttestato:"Si è verificato un errore durante la generazione degli attestati.",
        operazioneGenerica:"Si è verificato un errore durante l'esecuzione dell'operazione richiesta'.",
      }
    },
    congressModel: {
      name: {
        name: "Nome",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },

      slug: {
        name: "Slug",
        required: true,
        disabledInEditMode: true,
        type: "text",
      },

      codice: {
        name: "Codice",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },

      enabled: {
        name: "Abilitato",
        required: true,
        disabledInEditMode: false,
        type: "boolean",
      },

      dataInizio: {
        name: "Data Inizio",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "datetime",
      },
      dataFine: {
        name: "Data Fine",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "datetime",
      },

      status: {
        name: "Stato",
        required: true,
        disabledInEditMode: false,
        type: "select",
        options:[
          {value:"SETUP", name:"Setup"},
          {value:"ROAD_TO", name:"Road to"},
          {value:"LIVE", name:"Live"},
          {value:"STOPPED", name:"Stop"},
          {value:"ARCHIVED", name:"Archiviato"},
        ]
      },
      urlProgramma: {
        name: "Url Programma",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      urlSfondoAttestato: {
        name: "Url Sfondo Attestato",
        required: false,
        disabledInEditMode: false,
        type: "text",
      },
      urlSfondoAttestatoGiornaliero: {
        name: "Url Sfondo Attestato Giornarliero",
        required: false,
        disabledInEditMode: false,
        type: "text",
      },
      urlSfondoAttestatoFaculty: {
        name: "Url Sfondo Attestato Faculty",
        required: false,
        disabledInEditMode: false,
        type: "text",
      },
      urlSfondoAttestatoGiornalieroFaculty: {
        name: "Url Sfondo Attestato Giornarliero Faculty",
        required: false,
        disabledInEditMode: false,
        type: "text",
      },
      urlSfondoAttestatoMinicorsi: {
        name: "Url Sfondo Attestato Minicorsi",
        required: false,
        disabledInEditMode: false,
        type: "text",
      },
      immagineHeader: {
        name: "Immagine header - 1000x150",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      ordering: {
        name: "Ordinamento",
        required: true,
        disabledInEditMode: false,
        type: "text",
      }
    },

    beacon:{
      name:"Beacon",
      new_beacon:"Nuovo beacon",
      edit_beacon:"Modifica beacon",
      list_label:"Lista Beacon",
      confirm_delete:"Sicuro di voler cancellare il beacon selezionato?",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta del nuovo beacon.",
        edit:"Si è verificato un errore durante la modifica del beacon.",
        loading:"Si è verificato un errore durante il caricamento dei beacon.",
      }
    },
    beaconModel: {
      name: {
        name: "Nome",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },

      macAddress: {
        name: "macAddress",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },

    },

    helpDesk:{
      list_label:"Lista messaggi",
      update:"Aggiorna",
      placeholderInputReply:"Inserisci qui la tua risposta."
    },

    rooms:{
      name:"Stanza",
      new:"Nuova stanza",
      edit:"Modifica stanza",
      list_label:"Lista stanze",
      confirm_delete:"Sicuro di voler cancellare la stanza selezionata?",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta della nuova stanza.",
        edit:"Si è verificato un errore durante la modifica della stanza.",
        loading:"Si è verificato un errore durante il caricamento delle stanze.",
      }
    },
    roomsModel: {
      name: {
        name: "Nome",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      urlProgramma: {
        name: "Url Programma",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
    },

    sessions:{
      name:"Sessione",
      new:"Nuova sessione",
      edit:"Modifica sessione",
      list_label:"Lista sessioni",
      confirm_delete:"Sicuro di voler cancellare la sessione selezionata?",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta della nuova sessione.",
        edit:"Si è verificato un errore durante la modifica della sessione.",
        loading:"Si è verificato un errore durante il caricamento delle sezioni.",
      }
    },
    sessionModel: {
      name: {
        name: "Nome",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "text",
      },
      description: {
        name: "Descrizione",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "text",
      },
      start: {
        name: "Inizio",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "datetime",
      },
      stop: {
        name: "Fine",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "datetime",
      },
      realStart: {
        name: "Inizio reale",
        required: false,
        disabledInEditMode: true,
        disabledInCreateMode: true,
        disabledInInlineMode: true,
        type: "datetime",
      },
      realStop: {
        name: "Fine reale",
        required: false,
        disabledInEditMode: true,
        disabledInCreateMode: true,
        disabledInInlineMode: true,
        type: "datetime",
      },
      code: {
        name: "Codice",
        required: false,
        disabledInEditMode: false,
        disabledInCreateMode: true,
        disabledInInlineMode: true,
        type: "datetime",
      },
      status: {
        name: "Stato",
        required: true,
        disabledInEditMode: true,
        disabledInCreateMode: true,
        disabledInInlineMode: true,
        alreadySelectedInEditMode: true,
        alreadySelectedInCreateMode: true,
        type: "select",
        options:[
          {
            value:"DA_AVVIARE", name:"Da avviare"
          },
          {
            value:"IN_CORSO", name:"In corso"
          },
          {
            value:"TERMINATA", name:"Terminata"
          },
        ]
      },
      /*      presentation: {
              name: "Presentazioni",
              required: false,
              disabledInEditMode: false,
              type: "text",
            },
            clinic_cases: {
              name: "Casi Clinici",
              required: false,
              disabledInEditMode: false,
              type: "text",
            },
            surveys: {
              name: "Questionari",
              required: false,
              disabledInEditMode: false,
              type: "text",
            },*/

    },


    gateway:{
      name:"gateway",
      new_beacon:"Nuovo gateway",
      edit_beacon:"Modifica gateway",
      list_label:"Lista gateway",
      confirm_delete:"Sicuro di voler cancellare il gateway selezionato?",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta del nuovo gateway.",
        edit:"Si è verificato un errore durante la modifica del gateway. Verificare che il MAC address indicato non sia già associato ad altro gateway",
        loading:"Si è verificato un errore durante il caricamento dei gateway.",
      }
    },
    gatewayModel: {
      name: {
        name: "Nome",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },

      mac: {
        name: "mac",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
    },

    twitter:{
      list_label:"Lista messaggi",
      update:"Aggiorna",
      open_page_messaggi:"Visualizza Messaggi",
      confirm_delete:"Sicuro di voler cancellare l'associazione tra il gateway e la stanza?",
      errors:{
        edit:"Si è verificato un errore durante la modifica del messaggio.",
      },
      confirm_change_status:"Sei sicuro di voler modificare lo stato del twitter in risposto?",
    },

    minicorsi:{
      name:"Minicorsi",
      new:"Nuovo minicorso",
      edit:"Modifica minicorso",
      list_label:"Lista minicorsi",
      confirm_delete:"Sicuro di voler cancellare il minicorso?",
      errors:{
        add:"Si è verificato un errore durante la creazione del minicorso.",
        edit:"Si è verificato un errore durante la modifica del minicorso.",
        loading:"Si è verificato un errore durante il caricamento dei minicorsi.",
        loading_data:"Si è verificato un errore durante il caricamento dei dati. Riprovare",
      }
    },


    gatewayRoom:{
      name:"Gateway in una Stanza",
      new:"Nuovo gateway nella stanza",
      edit:"Modifica associazione",
      list_label:"Lista Gateway nelle stanze",
      confirm_delete:"Sicuro di voler cancellare l'associazione tra il gateway e la stanza?",
      errors:{
        add:"Si è verificato un errore durante la creazione dell'associazione tra il gateway e la stanza.",
        edit:"Si è verificato un errore durante la modifica dell'associazione tra il gateway e la stanza.",
        loading:"Si è verificato un errore durante il caricamento dei gateway.",
        loading_data:"Si è verificato un errore durante il caricamento dei dati. Riprovare",
        no_rooms_available:"Non ci sono utenti stanze disponibili",
        no_gateway_available_edit:"Modifica non possibile, in quanto non ci sono gateway liberi disponibili",
        no_gateway_available:"Non ci sono gateway liberi disponibili",
      }
    },
    gatewayRoomModel: {
      room: {
        name: "Stanza",
        type: "select",
        disabledInEditMode: true,
        alreadySelectedInEditMode: true,
        alreadySelectedInCreateMode: false,
        options:[
        ]
      },
      gateway: {
        name: "Gateway",
        type: "select",
        disabledInEditMode: false,
        alreadySelectedInCreateMode: false,
        alreadySelectedInEditMode: false,
        options:[
        ]
      },

    },

    clinicCase:{
      name:"Caso clinico",
      new:"Nuovo caso clinico",
      edit:"Modifica caso clinico",
      list_label:"Lista casi clinici",
      question:"Domanda",
      confirm_delete:"Sicuro di voler cancellare il caso clinico?",
      errors:{
        add:"Si è verificato un errore durante la creazione del caso clinico.",
        edit:"Si è verificato un errore durante la modifica del caso clinico.",
        loading:"Si è verificato un errore durante il caricamento dei casi clinici.",
        loading_data:"Si è verificato un errore durante il caricamento dei casi clinici. Riprovare"
      },
      questions:{
        titoloModale:"Domanda caso clinico",
        new:"Nuova domanda",
        edit:"Modifica domanda",
        confirm_delete:"Sicuro di voler cancellare la domanda?",
      }
    },
    clinicCaseModel: {
      title: {
        name: "Nome",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      description: {
        name: "Descrizione",
        required: true,
        disabledInEditMode: false,
        type: "text",
      }
    },
    questionClinicCaseModel: {
      title: {
        name: "Titolo",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      question: {
        name: "Domanda",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      onSet: {
        name: "Risposta Corretta",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      offSet: {
        name: "Risposte Errate",
        required: true,
        disabledInEditMode: false,
        type: "array",
        canUserAddElementsToArray:true,
        elementType:"text"
      },
    },

    casiClinici:{
      name:"Casi Clinici",
      questionari:"Casi Clinici",
      new:"Nuovo caso clinico",
      edit:"Modifica caso clinico",
      list_label:"Lista casi clinici",
      question:"Domanda",
      no_survey:"Nessun caso clinic associato a questa sessione",
      confirm_delete:"Sicuro di voler cancellare il caso clinico?",
      msg_modal_select:"Seleziona i casi clinici che vuoi aggiungere, e deseleziona quelli che vuoi rimuovere",
      msg_modal_add:"Seleziona gli utenti che vuoi aggiungere, e deseleziona quelli che vuoi rimuovere",
      errors:{
        add:"Si è verificato un errore durante la creazione del caso clinico.",
        edit:"Si è verificato un errore durante la modifica del caso clinico.",
        loading:"Si è verificato un errore durante il caricamento dei casi clinici.",
        editing:"Si è verificato un errore durante la modifica.",
        loading_data:"Si è verificato un errore durante il caricamento dei casi clinici. Riprovare",
        loading_users:"Si è verificato un errore durante il caricamento degli utenti. Riprovare"
      },
      questions:{
        new:"Nuova domanda",
        edit:"Modifica domanda",
        confirm_delete:"Sicuro di voler cancellare la domanda?",
      }
    },

    casoClinico:{
      no_data:"Nessun Caso Clinico associato a questa sessione",

      errors:{
        loading:"Si è verificato un errore durante il caricamento dei casi clinici.",
        editing:"Si è verificato un errore durante la modifica.",
      }
    },


    survey:{
      name:"Questionario",
      questionari:"Questionari",
      new:"Nuovo questionario",
      edit:"Modifica questionario",
      list_label:"Lista questionari",
      question:"Domanda",
      no_survey:"Nessun Questionario associato a questa sessione",
      confirm_delete:"Sicuro di voler cancellare il questionario?",
      msg_modal_select:"Seleziona i questionari che vuoi aggiungere, e deseleziona quelli che vuoi rimuovere",
      msg_modal_add:"Seleziona gli utenti che vuoi aggiungere, e deseleziona quelli che vuoi rimuovere",
      header_questionari:"Header questionari",
      errors:{
        add:"Si è verificato un errore durante la creazione del questionario.",
        edit:"Si è verificato un errore durante la modifica del questionario.",
        loading:"Si è verificato un errore durante il caricamento dei questionari.",
        editing:"Si è verificato un errore durante la modifica.",
        loading_data:"Si è verificato un errore durante il caricamento dei questionari. Riprovare",
        loading_users:"Si è verificato un errore durante il caricamento degli utenti. Riprovare"
      },
      questions:{
        new:"Nuova domanda",
        edit:"Modifica domanda",
        confirm_delete:"Sicuro di voler cancellare la domanda?",
      }
    },

    giornoPresentazione:{
      name:"Giorno Presentazione",
      questionari:"Giorni Presentazioni",
      new:"Nuova presentazione",
      edit:"Modifica presentazione",
      list_label:"Lista giorni presentazioni",
      question:"Domanda",
      no_session:"Nessuna sessione presente",
      confirm_delete:"Sicuro di voler cancellare il questionario?",
      msg_modal_select:"Seleziona i questionari che vuoi aggiungere, e deseleziona quelli che vuoi rimuovere",
      msg_modal_add:"Seleziona gli utenti che vuoi aggiungere, e deseleziona quelli che vuoi rimuovere",
      sessioniPresentazione:"Sessione presentazione",
      presentazione:"Presentazione",
      errors:{
        add:"Si è verificato un errore durante la creazione della presentazione.",
        edit:"Si è verificato un errore durante la modifica della presentazione.",
        delete:"Si è verificato un errore durante la cancellazione della presentazione.",
        loading:"Si è verificato un errore durante il caricamento delle presentazioni.",
        editing:"Si è verificato un errore durante la modifica.",
        loading_data:"Si è verificato un errore durante il caricamento delle presentazioni. Riprovare",
      },
      sessione:{
        errors:{
          add:"Si è verificato un errore durante la creazione della sessione.",
          edit:"Si è verificato un errore durante la modifica della sessione.",
          delete:"Si è verificato un errore durante la cancellazione della sessione."
        }
      }
    },

    questionSurveyModel: {
      title: {
        name: "Titolo",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      question: {
        name: "Domanda",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      value: {
        name: "Crediti CC risposta corretta",
        required: true,
        disabledInEditMode: false,
        type: "number",
      },
      onSet: {
        name: "Risposta Corretta",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      offSet: {
        name: "Risposte Errate",
        required: true,
        disabledInEditMode: false,
        type: "array",
        canUserAddElementsToArray:true,
        elementType:"text"
      },
    },

    questionSurveyModelReadOnly: {
      question: {
        name: "Domanda",
        required: true,
        disabledInEditMode: true,
        disabledInInlineMode: true,
        type: "text",
      },
      value: {
        name: "Crediti CC risposta corretta",
        required: true,
        disabledInEditMode: false,
        disabledInInlineMode: true,
        type: "number",
      },
      onSet: {
        name: "Risposta Corretta",
        required: true,
        disabledInEditMode: true,
        disabledInInlineMode: true,
        type: "text",
      },
      offSet: {
        name: "Risposte Errate",
        required: true,
        disabledInEditMode: true,
        disabledInInlineMode: true,
        hideDeleteButton: true,
        hideAddArrayElementButton: true,
        type: "array",
        canUserAddElementsToArray:false,
        elementType:"text"
      },
    },

    questionClinicCaseModelReadOnly: {
      question: {
        name: "Domanda",
        required: true,
        disabledInEditMode: true,
        disabledInInlineMode: true,
        type: "text",
      },
      onSet: {
        name: "Risposta Corretta",
        required: true,
        disabledInEditMode: true,
        disabledInInlineMode: true,
        type: "text",
      },
      offSet: {
        name: "Risposte Errate",
        required: true,
        disabledInEditMode: true,
        disabledInInlineMode: true,
        hideDeleteButton: true,
        hideAddArrayElementButton: true,
        type: "array",
        canUserAddElementsToArray:false,
        elementType:"text"
      },
    },



    presentation:{
      name:"Presentazione",
      new:"Nuova presentazione",
      edit:"Modifica presentazione",
      list_label:"Lista presentazioni",
      no_data_table:"Nessuna presentazione disponibile",
      confirm_delete:"Sicuro di voler cancellare la presentazione selezionata?",
      errors:{
        add:"Si è verificato un errore durante l'aggiunta della nuova presentazione.",
        edit:"Si è verificato un errore durante la modifica della presentazione.",
        loading:"Si è verificato un errore durante il caricamento delle presentazioni.",
      }
    },

    presentationModel: {
      description: {
        name: "Descrizione",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },

      url: {
        name: "Url",
        required: true,
        disabledInEditMode: false,
        type: "text",
      },
      ordering: {
        name: "Ordinamento",
        required: true,
        disabledInEditMode: false,
        disabledInCreateMode: false,
        type: "number",
      },
    },
    userLocation:{
      name:"Utente in Congresso",
      name_remote: "Utente Remoto in Congresso",
      new:"Nuovo utente del congresso",
      new_remote:"Nuovo utente remoto del congresso",
      edit:"Modifica utente",
      list_label:"Lista utenti localizzati",
      export_stats:"Esporta Statistiche",
      confirm_delete:"Sicuro di voler cancellare l'associazione tra l'utente ed il beacon?",
      errors:{
        add:"Si è verificato un errore durante la creazione dell'associazione tra utene e beacon.",
        edit:"Si è verificato un errore durante la modifica dell'associazione tra utene e beacon..",
        loading:"Si è verificato un errore durante il caricamento degli utenti del congresso.",
        deleting_localizations:"Si è verificato un errore durante l'eliminazione delle localizzazioni.",
        get_localizations:"Si è verificato un errore durante il caricamento delle localizzazioni.",
        deleteingLocations:"Si è verificato un errore durante la cancellazione delle localizzazioni",
        loading_data:"Si è verificato un errore durante il caricamento dei dati. Riprovare",
        no_user_available:"Non ci sono utenti liberi disponibili",
        no_beacon_available:"Non ci sono beacon liberi disponibili",
      }
    }
  },
});
